<template>
    <header class="relative">
      <div class="fixed top-0 left-0 z-30 w-full bg-white/80 backdrop-blur-sm">
        <div class="py-4 lg:py-3 border-b border-grey">
          <div class="mx-auto max-xl:px-4 xl:container">
            <nav class="flex items-center justify-between gap-4 lg:gap-8">
              <NuxtLink to="/">
                <NuxtPicture v-if="settings.data?.attributes.logo.data?.attributes.hash"
                             
                             loading="lazy"
                             :src="settings.data?.attributes.logo.data?.attributes.hash"
                             :alt="settings.data?.attributes.logo.data?.attributes.alternativeText || ''"
                             :imgAttrs="{
                                class:'h-[22px] w-auto',
                                
                             }"
                />
              </NuxtLink>
              <div>
                <button class="max-lg:text-sm text-center" @click="activeMenu.includes(999999) ? activeMenu = [] : activeMenu = [999999];">{{ settings.data.attributes.menu.title }}</button>
                <div v-if="settings.data.attributes.menu.menuItem.length > 0">
                  <Transition enter-active-class="duration-500 ease-out"
                              enter-from-class="opacity-0"
                              enter-to-class="opacity-100"
                              leave-active-class="duration-300 ease-in"
                              leave-from-class="opacity-100"
                              leave-to-class="opacity-0"
                  >
                    <div v-if="activeMenu.includes(999999)" v-click-away="onClickAway" class="fixed -translate-x-2/3 pt-6">
                      <div class="min-w-[200px] bg-white drop-shadow-lg py-3 rounded-lg flex gap-16">
                        <ul class="text-sm">
                          <li class="py-2 px-6 cursor-pointer" :class="{'border-t border-grey': subitem.topBorder}" v-for="subitem in settings.data.attributes.menu.menuItem">
                            <NuxtLink class="flex gap-3 items-center" :to="subitem.link"><Icon v-if="subitem.icon !== 'none'" :name="subitem.icon" :classes="'text-black/60 w-4 h-4'" /> {{ subitem.title }} </NuxtLink>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Transition>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </header>
</template>

<script lang="ts">
import { directive as clickAwayDirective } from "vue3-click-away";

export default {
  data () {
    return {
      open: false,
      activeMenu: [],
    }
  },

  directives: {
    ClickAway: clickAwayDirective,
  },

  methods: {
    onClickAway( event ) {
      this.activeMenu = []
    },
  },
}
</script>

<script setup lang="ts">
  import {useFetch} from "nuxt/app";
  import Icon from "../components/components/Icon.vue";

  const runtimeConfig = useRuntimeConfig();
  const { data:settings, pending:pendingSettings } = useFetch(runtimeConfig.public.STRAPI_API_URL + '/header-setting?pLevel', {
	 headers: {
      "Strapi-Response-Format": "v4",
    }
});
  const { data:navigation, pending } = useFetch(runtimeConfig.public.STRAPI_API_URL + '/navigations?pLevel', {
	 headers: {
      "Strapi-Response-Format": "v4",
    }
});
</script>
